import {
    ADD_VIDEO,
    DELETE_VIDEO

} from './UploadVideoActions'

const reducer = (state = {}, action) => {
    switch(action) {
        case ADD_VIDEO:
            return Object.assign({}, state, {

            })
    }
}

export default reducer
